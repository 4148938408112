import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetcher, useNavigate } from 'react-router-dom';

import { useUser } from '@f4s/api-client';
import { Alert, AvatarUploader, Button, Checkbox, cn, Icon, Input, Label } from '@f4s/ui';

import { InviteMultiselect, type Option } from '@/components/invite-multiselect';
import type { User } from '@/modules/user/schema';

import {
  useInviteWorkspaceMember,
  type UserIdEmail,
  type WorkspaceCreateActionData,
  type WorkspaceInviteMemberActionData,
} from '../actions';
import { WorkspaceSelect } from '../components/workspace-switcher';
import { useWorkspaces, type Workspace } from '../queries';
import { useMatchedWorkspace } from './use-workspace-match';

export const useWorkspaceInvite = ({
  defaultSpaceName,
  initialInvites,
  shouldRedirect,
  redirectTo,
  onSubmit,
  hideSwitcher = false,
}: {
  // Toggles first space header content
  defaultSpaceName?: string;
  initialInvites?: User[];
  onSubmit?: () => void;
  allowDemo?: boolean;
  // Redirect to the new space page after creation
  shouldRedirect?: boolean;
  redirectTo?: string;
  hideSwitcher?: boolean;
}) => {
  const { user } = useUser();
  const { data: existingWorkspaces } = useWorkspaces();
  const { workspace: matchedWorkspace } = useMatchedWorkspace();

  const workDomains = user.emailAddresses.flatMap(
    (e) => (e.type === 'work' && e.isVerified && e.emailAddress.split('@')[1]) || [],
  );
  const [workDomain, _setWorkDomain] = useState<string | undefined>(workDomains[0]);
  const [allowDomain, setAllowDomain] = useState<boolean>(true);

  // Space discoverability
  const [companyName, setCompanyName] = useState<string>('');
  const handleCompanyName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  }, []);

  const [isDiscoverable, setIsDiscoverable] = useState<boolean>(true);
  const handleIsDiscoverable = useCallback((isChecked: boolean) => {
    setIsDiscoverable(isChecked);
  }, []);

  const invitableWorkspaces = existingWorkspaces?.filter(
    (w) => w.isAdmin || w.permissions.allMembersCanInvite,
  );
  // If the matched workspace is a dummy, treat it as undefined in this component
  const workspace =
    !matchedWorkspace || matchedWorkspace.isDummy ? undefined : matchedWorkspace;

  const navigate = useNavigate();
  const inviteMembers = useInviteWorkspaceMember();

  // Pre-submit state
  const [members, setMembers] = useState<Option[]>(
    initialInvites?.map((u) => ({
      label: u.fullName,
      key: `${u.fullName} ${u.id}`,
      value: { type: 'user', userId: u.id, avatarUrl: u.avatarUrl },
    })) ?? [],
  );
  const [imageFile, setImageFile] = useState<File | null>(null);

  const [spaceName, setSpaceName] = useState<string>(defaultSpaceName ?? '');
  const handleSpaceName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSpaceName(e.target.value);
  }, []);

  // Post-submit state
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const createFetcher = useFetcher<WorkspaceCreateActionData>();
  useEffect(() => {
    if (createFetcher.state === 'idle' && createFetcher.data) {
      setErrorMessage(createFetcher.data.error ?? '');
      setIsCreating(false);
      if (onSubmit && !createFetcher.data.error) {
        onSubmit();
      }
    } else if (createFetcher.state !== 'idle') {
      setIsCreating(true);
    }
  }, [createFetcher.data, createFetcher.state, onSubmit]);

  const [isInviting, setIsInviting] = useState<boolean>(false);
  const inviteFetcher = useFetcher<WorkspaceInviteMemberActionData>();
  useEffect(() => {
    if (inviteFetcher.state === 'idle' && inviteFetcher.data) {
      setIsInviting(false);
      if (onSubmit) {
        onSubmit();
      }
    } else if (createFetcher.state !== 'idle') {
      setIsInviting(false);
    }
  }, [createFetcher.state, inviteFetcher.data, inviteFetcher.state, onSubmit]);

  const handleSelect = useCallback(
    (selectedSpace?: Workspace) => {
      if (selectedSpace) {
        navigate(`/spaces/${selectedSpace.slug}/invite`);
      } else {
        navigate('/spaces/create');
      }
    },
    [navigate],
  );

  const handleCreate = useCallback(() => {
    if (!workspace) {
      const userIdEmails: UserIdEmail[] = members.flatMap((m) => {
        if (m.value.type === 'user' || m.value.type === 'connection')
          return { userId: m.value.userId };
        if (m.value.type === 'email') return { emailAddress: m.value.emailAddress };
        return [];
      });
      const formData = new FormData();
      formData.append('name', spaceName.trim());
      formData.append('members', JSON.stringify(userIdEmails));

      if (imageFile) {
        formData.append('file', imageFile);
      }

      if (allowDomain && workDomain) {
        formData.append('allowedDomain', workDomain);
      }

      if (isDiscoverable) {
        formData.append('isDiscoverable', 'true');
      }

      if (shouldRedirect) {
        formData.append('shouldRedirect', 'true');
        if (redirectTo) {
          formData.append('redirectTo', redirectTo);
        }
      }

      // Posts to the route this component is mounted on, typically '/spaces/create'
      // Other routes may want to use their own loader, in particular for redirect behavior
      return createFetcher.submit(formData satisfies FormData, {
        method: 'POST',
        action: '/spaces/create',
        encType: 'multipart/form-data',
      });
    }
  }, [
    workspace,
    members,
    spaceName,
    imageFile,
    allowDomain,
    workDomain,
    isDiscoverable,
    shouldRedirect,
    createFetcher,
    redirectTo,
  ]);

  const handleInviteToExisting = useCallback(() => {
    if (workspace) {
      const userIdEmails: UserIdEmail[] = members.flatMap((m) => {
        if (m.value.type === 'user' || m.value.type === 'connection')
          return { userId: m.value.userId };
        if (m.value.type === 'email') return { emailAddress: m.value.emailAddress };
        return [];
      });
      setIsInviting(true);
      inviteMembers({ shouldRedirect, data: userIdEmails });
    }
  }, [workspace, members, inviteMembers, shouldRedirect]);

  const handleDomainCheck = useCallback((isChecked: boolean) => {
    setAllowDomain(isChecked);
  }, []);

  const title = workspace
    ? 'Invite to space'
    : !invitableWorkspaces
      ? ''
      : invitableWorkspaces.length === 0
        ? "Let's create your first space."
        : 'Create a new space';

  const subtitle = (
    <>
      <p className="text-pretty">
        Understanding your own motivations is powerful, but Marlee&apos;s real magic comes
        in helping you understand how to support and collaborate with the people around
        you.
      </p>
      <p className="text-pretty">
        Spaces help you organize groups of people. Whether it&apos;s your work team,
        family, or friends, build your community by inviting connections.
      </p>
    </>
  );

  const body = useMemo(() => {
    const showSwitcher =
      !hideSwitcher && invitableWorkspaces && invitableWorkspaces.length > 0;

    return (
      <div className="flex flex-col gap-8">
        {showSwitcher && (
          <div className="flex flex-col gap-2">
            <Label htmlFor="workspaceName">Select a space</Label>
            <WorkspaceSelect
              selected={workspace}
              onSelect={handleSelect}
              workspaces={invitableWorkspaces ?? []}
            />
          </div>
        )}

        {!workspace && (
          <div
            className={cn('flex flex-col items-center gap-6', !showSwitcher && 'pt-6')}
          >
            <AvatarUploader
              onFileSelected={(file: File) => setImageFile(file)}
              labelledBy="avatar-label"
              className="text-muted-foreground h-24 w-24 rounded-xl shadow-none ring-inset"
            />
            <Label id="avatar-label" className="text-muted-foreground font-normal">
              Add an icon or logo
            </Label>
          </div>
        )}

        <div className="flex flex-col gap-6">
          {!workspace && (
            <>
              <div className="flex flex-col gap-2">
                <Label htmlFor="workspaceName">
                  Space name<span className="text-muted-foreground">*</span>
                </Label>
                <Input
                  type="text"
                  id="workspaceName"
                  placeholder="Your company or team name"
                  value={spaceName}
                  onChange={handleSpaceName}
                  data-testid="spaces-create-name-input"
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <Label htmlFor="companyName">Company name</Label>
                </div>

                <Input
                  type="text"
                  id="companyName"
                  placeholder="Your company name"
                  value={companyName}
                  onChange={handleCompanyName}
                  data-testid="spaces-create-company-name-input"
                />
              </div>
            </>
          )}

          <div className="flex flex-col gap-2">
            <Label>Invite members</Label>
            <InviteMultiselect
              selections={members}
              onSelectionChange={(selections) => setMembers(selections)}
            />
            <Alert variant="ghost" icon={<Icon.Info size={16} />} className="mt-2">
              <div className="flex flex-col">
                <p>
                  We&apos;ll send an invite to each member to confirm that they want to
                  join your space.{' '}
                  {workspace?.permissions.memberViewEachOther === false ? null : (
                    <>
                      Members of a space are visible to one another, and are able to see
                      each other&apos;s results.
                    </>
                  )}
                </p>
              </div>
            </Alert>
          </div>

          <div className="flex flex-col gap-2">
            <Label>Search and Discovery Settings</Label>
            <div className="flex items-start gap-2">
              <Checkbox
                checked={isDiscoverable}
                onCheckedChange={handleIsDiscoverable}
                className="mt-0.5"
                data-testid="spaces-create-discoverable-checkbox"
              />
              <span className="text-pretty">
                Allow this space to be publicly discoverable via search
              </span>
            </div>
            <div className="flex items-start gap-2">
              <Checkbox
                checked={allowDomain}
                onCheckedChange={handleDomainCheck}
                className="mt-0.5"
                data-testid="spaces-create-allow-domain-checkbox"
              />
              <span className="text-pretty">
                Allow teammates with verified emails from the{' '}
                <span className="font-semibold">{workDomain}</span> domain to discover
                this space
              </span>
            </div>
          </div>

          {errorMessage && (
            <div className="text-destructive-foreground">{errorMessage}</div>
          )}
        </div>
      </div>
    );
  }, [
    hideSwitcher,
    invitableWorkspaces,
    workspace,
    handleSelect,
    spaceName,
    handleSpaceName,
    companyName,
    handleCompanyName,
    members,
    isDiscoverable,
    handleIsDiscoverable,
    allowDomain,
    handleDomainCheck,
    workDomain,
    errorMessage,
  ]);

  const button = useMemo(
    () =>
      workspace ? (
        <Button
          type="submit"
          onClick={() => handleInviteToExisting()}
          disabled={isCreating || isInviting}
          data-testid="spaces-invite-submit-button"
        >
          {isInviting ? 'Inviting to space' : 'Invite to space'}
        </Button>
      ) : (
        <Button
          type="submit"
          onClick={() => handleCreate()}
          disabled={isCreating || !spaceName}
          data-testid="spaces-create-submit-button"
        >
          {isCreating ? 'Creating space' : 'Create space'}
        </Button>
      ),
    [workspace, isCreating, isInviting, spaceName, handleInviteToExisting, handleCreate],
  );

  return { title, subtitle, body, button };
};
