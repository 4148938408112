import type { VariantProps } from 'cva';
import { useCallback, useEffect, useState, type ReactNode } from 'react';
import { useFetcher } from 'react-router-dom';

import { MagicLinkType } from '@f4s/types';
import {
  Alert,
  Button,
  CopyableInput,
  DialogTitle,
  Icon,
  Label,
  Switch,
  type buttonVariants,
} from '@f4s/ui';

import { DialogDrawer } from '@/components/dialog-drawer';
import { InviteMultiselect, type Option } from '@/components/invite-multiselect';
import { callAction } from '@/lib/utils';
import { useMagicLink } from '@/modules/invite/queries';

import { type UserIdEmail, type WorkspaceInviteMemberActionData } from '../actions';
import { useMatchedWorkspace } from '../hooks/use-workspace-match';
import { useWorkspaces, type Workspace } from '../queries';
import { WorkspaceSelect } from './workspace-switcher';

export const InviteModal = ({
  variant = 'secondary',
  onClick,
  open: externalOpen,
  onOpenChange,
  trigger,
}: {
  variant?: VariantProps<typeof buttonVariants>['variant'];
  onClick?: () => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: ReactNode | null;
}) => {
  const { data: existingWorkspaces } = useWorkspaces();
  const { workspace: matchedWorkspace } = useMatchedWorkspace();

  const [workspace, setWorkspace] = useState<Workspace | undefined>(
    matchedWorkspace?.isDummy ? undefined : matchedWorkspace,
  );
  const invitableWorkspaces = existingWorkspaces?.filter(
    (w) => w.isAdmin || w.permissions.allMembersCanInvite,
  );
  const [members, setMembers] = useState<Option[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(externalOpen ?? false);
  useEffect(() => {
    setIsOpen(externalOpen ?? false);
  }, [externalOpen]);

  // Handle submission state
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const toggleFetcher = useFetcher();
  const inviteFetcher = useFetcher<WorkspaceInviteMemberActionData>();

  // Magic Link
  const { data: link } = useMagicLink({
    type: MagicLinkType.organization,
    id: workspace?.id,
    enabled: true,
  });

  const handleSubmit = useCallback(() => {
    if (!workspace) return;
    const userIdEmails: UserIdEmail[] = members.flatMap((m) => {
      if (m.value.type === 'user' || m.value.type === 'connection')
        return { userId: m.value.userId };
      if (m.value.type === 'email') return { emailAddress: m.value.emailAddress };
      return [];
    });
    setIsInviting(true);
    inviteFetcher.submit(
      { data: userIdEmails, shouldRedirect: false },
      {
        action: `/spaces/${workspace.slug}/invite`,
        method: 'POST',
        encType: 'application/json',
      },
    );
  }, [inviteFetcher, members, workspace]);

  const handleOpenChange = useCallback(
    (isOpen_: boolean) => {
      setIsOpen(isOpen_);
      onOpenChange?.(isOpen_);
      if (!isOpen_) {
        // Dialog was closed, we should reset values to their initial state
        setWorkspace(matchedWorkspace?.isDummy ? undefined : matchedWorkspace);
      }
    },
    [matchedWorkspace, onOpenChange],
  );

  const handleSelections = useCallback((selections: Option[]) => {
    setMembers(selections);
  }, []);

  useEffect(() => {
    if (inviteFetcher.state === 'idle' && inviteFetcher.data) {
      setIsInviting(false);
      setMembers([]); // Clear out the members that were invited
      handleOpenChange(false); // Close the dialog
    }
  }, [handleOpenChange, inviteFetcher.data, inviteFetcher.state]);

  useEffect(() => {
    if (!isOpen && workspace?.id !== matchedWorkspace?.id) {
      // The space was switched while the dialog was closed, we should refresh the state
      setWorkspace(matchedWorkspace?.isDummy ? undefined : matchedWorkspace);
      setMembers([]);
    }
  }, [isOpen, matchedWorkspace, matchedWorkspace?.id, workspace?.id]);

  // TODO: Clean this up
  const toggleMagicLink = useCallback(() => {
    if (workspace) {
      if (link?.isActive)
        callAction('/invite/magic-link/disable', { token: link.token }, toggleFetcher);
      else
        callAction(
          '/invite/magic-link',
          { type: MagicLinkType.organization, id: workspace.id },
          toggleFetcher,
        );
    }
  }, [link?.isActive, link?.token, toggleFetcher, workspace]);

  return (
    <DialogDrawer
      dialogClassName="min-h-0"
      open={isOpen}
      onOpenChange={handleOpenChange}
      headerContent={<DialogTitle>Invite to space</DialogTitle>}
      asChild
      bodyContent={
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Label>Select a space</Label>
            <WorkspaceSelect
              workspaces={invitableWorkspaces ?? []}
              selected={workspace}
              onSelect={setWorkspace}
              variant="secondary"
              hideCreate
            />
          </div>

          <div className="flex flex-col gap-2">
            <Label>Invite members</Label>
            <InviteMultiselect
              selections={members}
              onSelectionChange={handleSelections}
            />
          </div>
          <Alert variant="info" icon={<Icon.Info size={16} weight="duotone" />}>
            <div className="flex flex-col">
              <p>
                We&apos;ll send an invite to each member to confirm that they want to join
                your space.{' '}
                {workspace?.permissions.memberViewEachOther === false ? null : (
                  <>
                    Members of a space are visible to one another, and are able to see
                    each other&apos;s results.
                  </>
                )}
              </p>
            </div>
          </Alert>

          {(link?.token || workspace?.isAdmin) && (
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <Label>Or invite with link</Label>
                <Switch
                  checked={link?.isActive ?? false}
                  onCheckedChange={toggleMagicLink}
                  disabled={
                    !workspace?.isAdmin ||
                    (toggleFetcher.data && toggleFetcher.state !== 'idle')
                  }
                />
              </div>

              {link?.token && (
                <>
                  <CopyableInput
                    value={`${window.origin}/app/invite/link/${link.token}`}
                    icon={<Icon.Link size={18} />}
                  />
                  <div className="text-muted-foreground text-sm">
                    Allow people to join the workspace with this link. Anyone who joins
                    with this link will have the member role.
                  </div>
                </>
              )}
            </div>
          )}
          <div className="flex justify-end">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isInviting || members.length === 0}
            >
              {isInviting ? 'Inviting to space' : 'Invite to space'}
            </Button>
          </div>
        </div>
      }
    >
      {trigger !== undefined ? (
        trigger
      ) : (
        <Button
          variant={variant}
          className="min-w-9 shrink-0 px-2 py-2 md:px-4"
          onClick={onClick}
        >
          <div className="shrink-0">
            <Icon.UserPlus size={18} weight="duotone" />
          </div>
          <div className="hidden md:flex">Invite</div>
        </Button>
      )}
    </DialogDrawer>
  );
};
